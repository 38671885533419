:root {
  --primary-color: #3f7c50;
  --secondary-color: #f1ab1e;
}

/* FONT SIZES */
.font11 {
  font-size: 0.688rem;
}
.font12 {
  font-size: 0.75rem;
}
.font13 {
  font-size: 0.813rem;
}
.font14 {
  font-size: 0.875rem;
}
.font15 {
  font-size: 0.938rem;
}
.font16 {
  font-size: 16px;
}
.font18 {
  font-size: 1.125rem;
}
.font20 {
  font-size: 1.25rem;
}
.font25 {
  font-size: 1.563rem;
}
.font30 {
  font-size: 1.875rem;
}
.font40 {
  font-size: 2.5rem;
}
.font60 {
  font-size: 3.75rem;
  line-height: 4.5rem;
}
@media only screen and (max-width: 859px) {
  .font11 {
    font-size: 0.688rem;
  }
  .font12 {
    font-size: 0.688rem;
  }
  .font13 {
    font-size: 0.75rem;
  }
  .font14 {
    font-size: 0.813rem;
  }
  .font15 {
    font-size: 0.875rem;
  }
  .font18 {
    font-size: 0.938rem;
  }
  .font20 {
    font-size: 1.125rem;
  }
  .font25 {
    font-size: 1.25rem;
  }
  .font30 {
    font-size: 1.5rem;
  }
  .font40 {
    font-size: 1.875rem;
  }
  .font60 {
    font-size: 2.8rem;
  }
}

/* BORDER RADIUS */
.radius8 {
  border-radius: 1rem;
}
.radius6 {
  border-radius: 0.6rem;
}

/* COLORS */
.darkColor {
  color: #0b093b;
}
.lightColor {
  color: #f5f5f5;
}
.greyColor {
  color: #707070;
}
.whiteColor {
  color: #fff;
}

/* HELLPERS */
.textCenter {
  text-align: center;
}
.animate {
  transition: 0.3s ease;
}
.pointer {
  cursor: pointer;
}
.relative {
  position: relative;
}
.textCenter {
  text-align: center;
}
.textLeft {
  text-align: left;
}
.textRight {
  text-align: right;
}
.x100 {
  width: 100%;
}
.h100 {
  height: 100%;
}
.vh100 {
  height: 100vh;
}

/* FLEXBOX */
.flex {
  display: flex;
}
.flexGrow {
  flex-grow: 1;
}
.flexSpaceCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexSpaceAround {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.flexNullCenter {
  display: flex;
  align-items: center;
}
.flexColumn {
  display: flex;
  flex-direction: column;
}
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexSpaceNull {
  display: flex;
  justify-content: space-between;
}

.flexEnd {
  display: flex;
  align-items: end;
  justify-content: flex-end;
}
.flexWrap {
  flex-wrap: wrap;
}

/* BACKGROUNDS */
.whiteBg {
  background-color: white;
}

/*SPECIAL TEAM REQUEST FORM*/
.request-btn {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.submit-btn {
  background-color: #28a745;
  color: #ffffff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.form-field {
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  box-sizing: border-box;
}

.ant-picker {
  min-width: 200px;
}

.select-input {
  position: absolute;
}

.text-head {
  /* text-align: center; */
  font-weight: bold;
  font-size: 1em;
}

.span-green {
  padding: 5px;
  background-color: #286d38;
  color: white;
  border-radius: 5px;
}

.span-orange {
  padding: 5px;
  background-color: rgb(173, 122, 28);
  color: white;
  border-radius: 5px;
}

.span-blue {
  padding: 5px;
  background-color: rgb(15, 68, 160);
  color: white;
  border-radius: 5px;
}

.success-span {
  background-color: #286d38;
  color: white;
  padding: 3px;
  border-radius: 5px;
  white-space: nowrap;
}

.no-wrap {
  white-space: nowrap !important;
}

.btn-orange {
  background-color: orange;
  color: white;
  padding: 7px;
  display: inline-block !important;
  margin: 1px;
}

.btn-danger {
  background-color: rgb(171, 45, 45);
  color: white;
  display: inline-block !important;
  padding: 7px;
  margin: 1px;
}

.btn-green {
  background-color: rgb(26, 130, 78);
  color: white;
  display: inline-block !important;
  padding: 7px;
  margin: 1px;
}

.mainColor {
  color: var(--primary-color);
}
.mainBg {
  background-color: var(--primary-color);
}

.radius5 {
  border-radius: 5px;
}

.radius10 {
  border-radius: 10px;
}

.regular {
  font-weight: 500;
}

.bold {
  font-weight: bold;
}

.semiBold {
  font-weight: 600;
}

.extraBold {
  font-weight: 900;
}

.p5 {
  padding: 5px;
}
.p10 {
  padding: 10px;
}
.p15 {
  padding: 15px;
}
.p20 {
  padding: 20px;
}

.textDisabled {
  color: #94a4c4;
}

.capitalize {
  text-transform: capitalize;
}

.scroll {
  overflow: scroll;
}

.hidden {
  overflow: hidden;
}

.gap5 {
  gap: 5px;
}
.gap10 {
  gap: 10px;
}
.gap15 {
  gap: 15px;
}
.gap20 {
  gap: 20px;
}

.mt5 {
  margin-top: 5px;
}
