/* HTML: <div class="loader"></div> */
.loader {
  width: 120px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(farthest-side, var(--primary-color) 94%, #0000)
      top/3px 3px no-repeat,
    conic-gradient(#0000 30%, var(--primary-color));
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 3px), #000 0);
  animation: l13 2s infinite linear;
}
@keyframes l13 {
  100% {
    transform: rotate(1turn);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

.pulse {
  animation: pulse 2s infinite;
}

.loaderPulse {
  position: fixed;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #3f7c508b;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
